const mainAppLinks = [
    { title: 'Accueil', url: '/' },
    { title: 'Vie des AET', url: '/life' },
    { title: 'Organisation', url: '/organisation' },
    { title: 'Blog', url: '/blog' },
    // { title: 'Actualites', url: '/clubs' },
    // { title: 'A propos', url: '/about' },
];

const storeAppLinks = [
    { title: 'Acceuil', url: '/store' },
    { title: 'Voir tout', url: '/store/all' },
    { title: 'Aller au site AAET', url: '/' },
    { title: 'contact', url: '/store/contact' },
];

const categoriesLinks = [
    { title: 'Actualités', url: '/blog?categories=Actualités' },
    { title: 'Technology', url: '/blog?categories=Technology' },
    { title: 'Travel', url: '/blog?categories=Travel' },
    { title: 'Business', url: '/blog?categories=Business' },
    { title: 'Economy', url: '/blog?categories=Economy' },
    { title: 'Sports', url: '/blog?categories=Sports' },
];


export { mainAppLinks, categoriesLinks , storeAppLinks};
